<template>
  <div class="dashboard-documnets white radius-15 pa-2 pb-4">
    <profile-head
      :leftTitle="$t('dashboard.allRequests')"
      :title="$t('dashboard.orderAssignment')"
    ></profile-head>

    <div class="bluebgdash px-5 py-6 mt-3 radius-5">
      <v-row>
        <v-col cols="12" md="6" lg="6">
          <user-details :info="data"></user-details>
        </v-col>

        <!-- steps -->
        <v-col cols="12" md="6" lg="6">
          <user-steps
            :info="data.doctor[0]"
            :doctor_status_id="data.doctor_status_id"
            @showTimePicker="timeHandler"
            @decline="declineHandler"
            @updateData="updateDataHandler"
            :key="dateUpdated"
            :dateUpdated="dateUpdated"
          ></user-steps>
        </v-col>
      </v-row>
    </div>
    <div class="bluebgdash mt-3 py-6 px-5 radius-5">
      <user-dates :dates="data.interview_times" :info="data" :key="dateUpdated"></user-dates>
    </div>

    <!-- date dialog -->
    <v-dialog
      v-model="dialog"
      max-width="550px"
      :content-class="isMobile ? 'pa-4 radius-5 white' : 'pa-6 white radius-16'"
    >
      <enter-time
        :key="reOpen"
        @exit="[(reOpen = !reOpen), (dialog = false)]"
        @click="sendTimes"
      ></enter-time>
    </v-dialog>
  </div>
</template>

<script>
import ProfileHead from "../../components/ProfileHead.vue";
import UserDetails from "./components/Details.vue";
import UserSteps from "./components/Steps.vue";
import UserDates from "./components/Dates.vue";
import EnterTime from "../../components/dialogs/EnterTime.vue";
export default {
  components: { ProfileHead, UserDetails, UserSteps, UserDates, EnterTime },
  data: () => ({
    dialog: false,
    reOpen: false,
    dateUpdated: false,
    data: {},
  }),
  methods: {
    timeHandler() {
      this.dialog = true;
    },
    async declineHandler() {
      let { data } = await this.$axios.post("orders/change/doctor/status", {
        order_id: this.$route.params.id,
        doctor_id: this.$route.params.doctorID,
        status_id: 6,
      });
      if (data.success) {
        this.$store.dispatch("showSnack", {
          text: "Doctor order has been declined successfully",
          color: "success",
        });
        this.$router.go(-1);
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error",
        });
      }
    },
    async sendTimes(val) {
      let obj = {
        order_id: this.$route.params.id,
        doctor_id: this.$route.params.doctorID,
        dates: val,
      };
      let { data } = await this.$axios.post("orders/doctors/times", obj);

      if (data.success) {
        this.$store.dispatch("showSnack", {
          text: "Times added Successfully",
          color: "success",
        });
        this.dialog = false;
        await this.$axios.post("orders/change/doctor/status", {
          order_id: this.$route.params.id,
          doctor_id: this.$route.params.doctorID,
          status_id: 3,
        });
        await this.getData();
        this.dateUpdated = !this.dateUpdated;
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error",
        });
      }
    },
    async getData() {
      let obj = {
        order_id: this.$route.params.id,
        doctor_id: this.$route.params.doctorID,
      };
      let { data } = await this.$axios.post("order/details", obj);
      if (data.success) {
        this.data = data.data[0];
      }
    },
    async updateDataHandler() {
      await this.getData();
      this.dateUpdated = !this.dateUpdated;
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style lang="scss"></style>
