<template>
  <v-stepper v-model="step" alt-labels elevation="0">
    <v-stepper-header elevation="0">
      <v-stepper-step step="1" color="register-primary" :complete="step > 1">
        <span class="font-16 grey--text">{{$t('dashboard.inProgress')}} </span>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="2" color="register-primary" :complete="step > 2">
        <span class="font-16 grey--text">{{$t('dashboard.interview')}}</span>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3" color="register-primary" :complete="step > 3">
        <span class="font-16 grey--text">{{$t('dashboard.accept')}}</span>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="4" color="register-primary" :complete="step > 4">
        <span class="font-16 grey--text">{{$t('dashboard.closed')}}</span>
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items v-if="doctor_status_id != 5 && doctor_status_id != 6">
      <!-- step 1 -->
      <v-stepper-content
        :step="i + 1"
        class="text-right"
        v-for="(step, i) in steps"
        :key="step.id"
      >
        <v-btn
          height="50px"
          width="80px"
          elevation="0"
          color="#FFABAB"
          dark
          @click="$emit('decline')"
          ><span class="font-14 white--text py-1 d-inline-block"> {{$t('dashboard.decline')}}</span>
        </v-btn>
        <v-btn
          height="50px"
          width="200px"
          elevation="0"
          color="register-primary"
          dark
          class="mx-5"
          @click="stepHandler(step)"
          ><span class="font-14 d-inline-block px-4 py-1">
            {{ step.btnTitle }}
          </span></v-btn
        >
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    dateUpdated: {
      type: Boolean,
      default: false,
    },
    doctor_status_id: {
      type: Number,
      default: 0,
    },

  },
  data: (vm) => ({
    step: 1,
    steps: [
      { btnTitle: vm.$t("dashboard.next"), id: 1 },
      { btnTitle: vm.$t("dashboard.next"), id: 2 },
      { btnTitle: vm.$t("dashboard.closedAssignment"), id: 3 },
      { btnTitle: vm.$t("dashboard.next"), id: 4 },
    ],
  }),
  watch: {
    dateUpdated: {
      handler() {
        this.step = this.doctor_status_id - 1;
      },
    },
  },
  methods: {
    async stepHandler() {
      if (this.doctor_status_id == 2) {
        this.$emit("showTimePicker");
        return;
      }
      if (this.doctor_status_id == 3) {
        await this.$axios.post("orders/change/doctor/status", {
          order_id: this.$route.params.id,
          doctor_id: this.$route.params.doctorID,
          status_id: 4,
        });

        this.$emit("updateData");
        return;
      }
      if (this.doctor_status_id == 4) {
        await this.$axios.post("orders/change/doctor/status", {
          order_id: this.$route.params.id,
          doctor_id: this.$route.params.doctorID,
          status_id: 5,
        });

        this.$emit("updateData");
        return;
      }
    },
  },
  created() {
    if (this.info.id) {
      this.step = this.doctor_status_id - 1;
    }
  },
};
</script>

<style lang="scss">
.v-stepper__header {
  box-shadow: none;
}
.v-stepper {
  background: transparent !important;
}
.v-stepper__step__step {
  width: 40px;
  height: 40px;
  background: #e6e6e6 !important;
}
.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin: 45px -64px 0;
}
</style>
