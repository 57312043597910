<template>
  <!-- <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="dashbase-input">
        <v-text-field
          :label="label"
          outlined
          append-icon="mdi-clock-time-four-outline"
          readonly
          v-bind="attrs"
          :placeholder="placeholder"
          v-on="on"
          dense
          :hide-details="true"
          :value="value"
          @input="$emit('input', $event)"
        ></v-text-field>
      </div>
    </template>
    <v-time-picker
      v-if="menu"
      v-model="time"
      full-width
      format="ampm"
      @click:minute="timePickedHandler(time)"
      @change="$emit('change')"
    ></v-time-picker>
  </v-menu> -->

  <vue-timepicker
  :minute-interval="15"
    :value="value"
    @input="$emit('input', $event)"
    close-on-complete
  ></vue-timepicker>
</template>

<script>
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  props: {
    value: {
      validator: () => true,
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  components: {
    VueTimepicker,
  },
  data: () => ({
    menu: false,
    time: null,
  }),
  methods: {
    timePickedHandler(time) {
      this.$emit("time", time);
      this.$refs.menu.save(time);
    },
  },
};
</script>

<style></style>
