<template>
  <div class="d-flex">
    <!-- user image -->
    <img
      class="radius-10 object-cover "
      :class="$vuetify.rtl ? 'flip ml-5': 'mr-5'"
      height="240px"
      width="160px"
      :src="require('@/assets/images/register-bg.png')"
      alt=""
    />
    <!-- :src="info.doctor[0].profile" -->

    <!-- user details -->
    <div>
      <span class="font-600 font-20 darkbord--text d-block"
        >{{ info.doctor[0].first_name }}
      </span>
      <div>
        <span class="font-18 d-block mt-1"
          >{{ $t("forms.specialities") }}:
          {{ info.doctor[0].specialization }}</span
        >
        <span class="font-18 d-block mt-1"
          >{{ $t("forms.gender") }}: {{ info.doctor[0].gender }}</span
        >
        <span class="font-18 d-block mt-1"
          >{{ $t("forms.jobType") }}: {{ info.doctor[0].job_type }}</span
        >
        <span class="font-18 d-block mt-1"
          >{{ $t("forms.nationality") }}: {{ info.doctor[0].nationality }}</span
        >
        <span
          class="font-18 d-block mt-1"
          v-if="info.doctor_status_id != 2 && info.assiged_at"
        >
          {{ $t("forms.assignedAt") }}:
          {{
            $moment(info.assiged_at).format("l") +
            " " +
            $moment(info.assiged_at).format("LT")
          }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style></style>
