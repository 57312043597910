<template>
  <div>
    <div class="d-flex align-center justify-space-between mb-3">
      <span
        class="d-block font-700 darkbord--text"
        :class="isMobile ? 'font-18' : 'font-24 '"
        >{{$t('dashboard.enterDateTime')}}</span
      >
      <img
        class="cursor-pointer"
        @click="$emit('exit')"
        :src="require('@/assets/images/dashboard/icons/close-black.svg')"
      />
    </div>

    <v-row class="mt-2">
      <v-col cols="12" md="12" class="pb-0">
        <div class="bluebgdash">
          <v-date-picker
            no-title
            full-width
            class="date-picker"
            color="register-primary"
            @input="changeDate"
            v-model="obj.date"
            :events="arrayEvents"
            event-color="green"
            :min="new Date().toISOString().substr(0, 10)"
          ></v-date-picker>
        </div>
      </v-col>
      <v-col
        cols="12"
        v-if="dates[selectedTimesIndex] && dates[selectedTimesIndex].times"
        :key="timeKeyUpdate"
      >
        <div
          v-for="(time, i) in dates[selectedTimesIndex].times"
          :key="i"
          class="d-flex justify-center text-center"
          :class="i == dates[selectedTimesIndex].times.length - 1 ?  'mb-12' :''"
        >
          <div style="width: 100%;">
            <span class="d-block font-16 font-600 darktext--text relative"
              >{{$t('dashboard.startTime')}}
            </span>
            <base-time
              v-model="time.startTime"
              placeholder="select time"
              @input="handleSelectTime($event, 'startTime', i)"
              :key="keyUpdated"
              @change="changeTime"
            ></base-time>
          </div>
          <div style="width: 100%; padding: 0px 10px">
            <span class="d-block font-16 font-600 darktext--text relative"
              >{{$t('dashboard.endTime')}}
            </span>
            <base-time
              v-model="time.endTime"
              placeholder="select time"
              @input="handleSelectTime($event, 'endTime', i)"
              :key="keyUpdated"
              @change="changeTime"
            ></base-time>
          </div>
          <div  class="text-center">
            <img
              @click="removeDate(i)"
              class="cursor-pointer mt-7"
              :src="require('@/assets/images/dashboard/icons/minus-circle.svg')"
            />
          </div>
        </div>
      </v-col>

      <v-col cols="12" v-else>
        <div class="text-center">
          <span>{{ $t('dashboard.noTimeSelected') }}</span>
        </div>
      </v-col>

      <v-col cols="12" md="6" :class="isMd ? '' : 'mt-4'">
        <v-btn
          color="#A8F6E9"
          dark
          elevation="0"
          class="radius-5"
          @click="addMoreTime"
          block
          height="44px"
          ><div class="d-flex align-center justify-center">
            <img
              :src="require('@/assets/images/dashboard/icons/plus.svg')"
              alt=""
            />
            <span class="font-700 font-16 darkprimary--text"
              >{{ $t('dashboard.addMoreTime') }}</span
            >
          </div></v-btn
        >
      </v-col>
      <v-col cols="12" md="6" :class="isMd ? '' : 'mt-4'">
        <v-btn
          color="register-primary"
          dark
          elevation="0"
          class="radius-5"
          @click="clickHandler"
          block
          height="44px"
          ><span class="font-700 font-16 white--text">{{$t('forms.send')}}</span></v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BaseTime from "../BaseTime.vue";
export default {
  components: { BaseTime },
  data: () => ({
    dates: [
      {
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        times: [],
      },
    ],
    selectedTimesIndex: 0,
    obj: {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    },
    keyUpdated: false,
    timeKeyUpdate: false,
    arrayEvents: [],
  }),
  watch: {
    dates: {
      handler() {
        this.dates.forEach((date, idx) => {
          if (date.times[0].startTime || date.times[0].endTime) {
            this.arrayEvents[idx] = date.date;
          }
        });
      },
      deep: true,
    },
    timeKeyUpdate: {
      handler() {
        this.dates.forEach((date, idx) => {
          if (date.times[0].startTime || date.times[0].endTime) {
            this.arrayEvents[idx] = date.date;
          }
        });
      },
      deep: true,
    },
  },
  methods: {
    handleSelectTime(val, type, indx) {
      if (val.HH) {
        this.dates[this.selectedTimesIndex].times[indx][type] =
          val.HH + ":" + val.mm;
      } else {
        this.dates[this.selectedTimesIndex].times[indx][type] = val;
      }
    },
    changeDate(event) {
      let isExist = this.dates.findIndex((date) => date.date == event);
      if (isExist == -1) {
        isExist = this.dates.length;
        this.dates.push({
          date: event,
          times: [],
        });
      }
      this.selectedTimesIndex = isExist;
      this.timeKeyUpdate = !this.timeKeyUpdate;

    },
    changeTime() {
      this.timeKeyUpdate = !this.timeKeyUpdate;
    },
    addMoreTime() {
      this.dates[this.selectedTimesIndex].times.push({ startTime: "00:00", endTime: "00:15" });
    },
    removeDate(index) {
      this.dates[this.selectedTimesIndex].times = this.dates[
        this.selectedTimesIndex
      ].times.filter((_, idx) => idx != index);
    },
    clickHandler() {
      let emptyVal = false;

      this.dates.forEach((date) => {
        date.times = date.times.filter(
          (time) => time.startTime || time.endTime
        );
      });

      //   filter to remove empty times
      this.dates = this.dates.filter((date) => date.times.length > 0);

      //   check if start or end time is empty
      this.dates.forEach((date) => {
        date.times.forEach((time) => {
          if (!time.startTime || !time.endTime) {
            emptyVal = true;
            this.$store.dispatch("showSnack", {
              text: `time in ${date.date} must not be empty`,
              color: "error",
            });
          }
        });
      });
      if (this.dates.length == 0) {
        this.$store.dispatch("showSnack", {
          text: `you must select one date at least`,
          color: "error",
        });
        return;
      }
      if (emptyVal) {
        return;
      }
      this.$emit("click", this.dates);
    },
  },
  mounted() {
    this.dates.forEach((date, idx) => {
      if (date.times[0].startTime || date.times[0].endTime) {
        this.arrayEvents[idx] = date.date;
      }
    });
  },
};
</script>

<style>
.vue__time-picker,
.vue__time-picker input{
  width: 100% !important;
  text-align: center;
}
</style>
