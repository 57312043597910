<template>
  <div v-if="info.doctor_status_id != 2">
    <span class="font-700 d-block font-18">{{ $t("dashboard.dateTime") }}</span>
    <v-row class="mt-3">
      <v-col cols="12" md="5">
        <div class="blue-bg-opacity radius-5 pa-2">
          <!-- date picker -->
          <v-date-picker
            class="date-picker"
            v-model="date"
            no-title
            full-width
            color="register-primary"
            @change="dateChanged"
            :allowed-dates="allowedDates"
          ></v-date-picker>
        </div>
      </v-col>
      <v-col cols="12" md="7">
        <div class="pa-3 px-2 text-center">
          <v-row class="blue-bg-opacity radius-5 mb-2">
            <v-col md="4" cols="6" v-for="(time, i) in times" :key="i">
              <div class="white radius-5 px-4 py-3">
                <span
                  class="font-20 font-600 d-inline-block darktext--text mr-1"
                >
                  {{
                    time.startTime.HH != null
                      ? time.startTime.HH
                      : time.startTime
                  }}{{
                    time.startTime.mm != null ? time.startTime.mm : ""
                  }}</span
                >
                -
                <span
                  class="font-20 font-600 d-inline-block greytext--text ml-1"
                >
                  {{ time.endTime.HH != null ? time.endTime.HH : time.endTime
                  }}{{ time.endTime.mm != null ? time.endTime.mm : "" }}</span
                >
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    dates: {
      type: Array,
      default: () => [],
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    pickerDates: [],
    date: "",
    times: [],
  }),
  methods: {
    allowedDates(val) {
      return this.pickerDates.includes(val);
    },
    dateChanged(event) {
      let currentTimes = this.dates.filter((date) => date.date == event)[0]
        .times;
      this.times = currentTimes;
    },
  },
  created() {
    if (this.dates.length > 0) {
      this.pickerDates = this.dates.map((date) => date.date);
      this.date = this.dates[0].date;
      this.dateChanged(this.date);
    }
  },
};
</script>

<style lang="scss">
.blue-bg-opacity {
}
.v-picker__body {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding:  2px 0px;
}
.v-date-picker-table {
  height: auto;
  tbody {
    td {
      height: 45px;
      width: 30px;
      padding: 1px;
      button {
        border: 1px solid rgb(233, 233, 233) !important;
        background: white;
        border-radius: 4px !important;
        height: 100% !important;
        width: 100% !important;
        div {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
